export const baseURL = "https://healthapi.apyhi.com";

// export const getToken = () => {
//     const token = JSON.parse(localStorage.getItem("profile"))?.token;
//     const config = {
//         headers: { authorization: `Bearer ${token}` },
//     };
//     return config;
// };

