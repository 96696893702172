import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container:{
    padding:10,
    margin:10,
  },
  selectApp:{
    width:'99%',
    margin:10
  },
  header:{
    fontWeight:'bold',
    textAlign:'center'
  },
  tableCell:{
    textAlign:'center'
  }
}));

export default useStyles;