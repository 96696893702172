import { Box, Typography } from "@material-ui/core";
import { gapi } from "gapi-script";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import { APPYHIGH_ACCOUNT, HOME_ROUTE, JWT_TOKEN } from "../../utils/constants";
import useStyles from "./styles";

const Login = () => {
  const classes = useStyles();
  const [loginMessage, setLoginMessage] = useState();
  const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
  const navigate = useNavigate();

  const handleSuccess = (response) => {
    const { email } = response.profileObj;
    if (email.includes(APPYHIGH_ACCOUNT)) {
      Cookies.set(JWT_TOKEN, response.tokenId, { expires: 60 });
      window.location.reload();
    } else {
      setLoginMessage("Please Use AppyHigh Account");
    }
  };

  const handleFailure = (err) => {
    setLoginMessage("Login Failed, Try Again!");
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    function authenticate() {
      const jwtToken = Cookies.get(JWT_TOKEN);
      if (jwtToken !== undefined) navigate(HOME_ROUTE);
    }
    gapi.load("client:auth2", start);
    authenticate();
  }, []);

  return (
    <Box className={classes.container}>
      <GoogleLogin
        clientId={clientId}
        buttonText="Continue With Google"
        onSuccess={handleSuccess}
        onFailure={handleFailure}
        cookiePolicy="single_host_origin"
        theme="dark"
      />
      <Typography variant="h6" className={classes.loginMessage}>
        {loginMessage}
      </Typography>
    </Box>
  );
};

export default Login;
