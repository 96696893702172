
import './App.css';

import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import Form  from "../src/component/Form/Form";
import Dashboard from "./component/Dashboard";
import Detail from "../src/component/AppDetail/detail.js";
import Login from './component/Login/Login';
import ProtectedRoute from './component/ProtectedRoute/ProtectedRoute';
import { HOME_ROUTE, REGISTER_APP_ROUTE, APPID_ROUTE } from './utils/constants';


function App() {
  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path={HOME_ROUTE} element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path={REGISTER_APP_ROUTE} element={
            <ProtectedRoute>
              <Form />
            </ProtectedRoute>
          } />
          <Route path={APPID_ROUTE} element={
            <ProtectedRoute>
              <Detail />
            </ProtectedRoute>
          } />

        </Routes>
      </div>
  );
}

export default App;
