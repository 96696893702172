import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container:{
    display:'flex',
    width:'100vw',
    height:'100vh',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column'
  },
  loginMessage:{
    color:'red',
    margin:10,
    fontSize:18
  }
}));

export default useStyles;