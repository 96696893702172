import {
  INFORMATIONAL,
  SUCCESSFUL,
  REDIRECTION,
  CLIENT_ERROR,
  SERVER_ERROR,
  INFORMATIONAL_LABEL,
  SUCCESSFUL_LABEL,
  REDIRECTION_LABEL,
  CLIENT_ERROR_LABEL,
  SERVER_ERROR_LABEL,
} from "./utils/constants";
import { PATTERNS } from "./utils/constants";

export const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const getStatusCodeTypeAndLabel = (statusCode) => {
  if (100 <= statusCode && statusCode < 200) {
    return {
      statusCodeType: INFORMATIONAL,
      statusCodeLabel: INFORMATIONAL_LABEL,
    };
  } else if (200 <= statusCode && statusCode < 300) {
    return { statusCodeType: SUCCESSFUL, statusCodeLabel: SUCCESSFUL_LABEL };
  } else if (300 <= statusCode && statusCode < 400) {
    return { statusCodeType: REDIRECTION, statusCodeLabel: REDIRECTION_LABEL };
  } else if (400 <= statusCode && statusCode < 500) {
    return {
      statusCodeType: CLIENT_ERROR,
      statusCodeLabel: CLIENT_ERROR_LABEL,
    };
  } else if (500 <= statusCode && statusCode < 600) {
    return {
      statusCodeType: SERVER_ERROR,
      statusCodeLabel: SERVER_ERROR_LABEL,
    };
  }
};
export const validateEmail = (email) => PATTERNS.EMAIL.test(email);
