import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { baseURL } from "../../utils/api";
import { JWT_TOKEN } from "../../utils/constants";

const API = axios.create({
  baseURL: `${baseURL}`,
  headers: { Authorization: `bearer ${Cookies.get(JWT_TOKEN)}` },
});

// const Token_Api = axios.create({
//     baseURL: `${baseURL}/auth`,
//     headers: {
//         "content-type": "application/json",npm
//         authorization: `Bearer ${token && token.access}`,
//     },
// });

export const registerAction = async (appData) => {
  try {
    const data = await API.post("/register-cron", appData);
    return data;
  } catch (error) {
    toast(error);
    return error;
  }
};

export const updateAction = async (appData, endpointId) => {
  try {
    const data = await API.put(`/edit-cron?id=${endpointId}`, appData);
    return data;
  } catch (error) {
    toast(error);
    return error;
  }
};

export const getAppData = async () => {
  try {
    const data = await API.get("/fetch-app-names");
    return data;
  } catch (error) {
    return error;
  }
};

export const getCronAppData = async () => {
  try {
    const data = await API.get("/get-registered-cron-app");
    return data;
  } catch (error) {
    return error;
  }
};

export const getCronAppDataById = async (id) => {
  try {
    const data = await API.get(`/get-registered-cron-by-id?id=${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getCronAppDataByAppId = async (id) => {
  try {
    const data = await API.get(`/get-registered-cron-by-appid?appId=${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteEndpoint = async (id) => {
  try {
    const data = await API.delete(`/delete-cron?id=${id}`);
    return data;
  } catch (error) {
    return error;
  }
};