import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "80%",
    margin: 20,
  },
  form: {
    backgroundColor: "#FFF",
    width: "500px",
    padding: "50px 20px",
    textAlign: "center",
    margin: "50px auto",
    borderRadius: 10,
    boxShadow: "0px 0px 6px #FFF",
  },
  formMessage: {
    fontSize: 16,
    padding: "20px",
    textAlign: "center",
    margin: "0",
    borderRadius: 10,
    boxShadow: "0px 0px 6px #FFF",
    color: "red",
  },
  formHeading: {
    marginBottom: "20px",
    fontSize: "22px",
  },
  successText: {
    color: "green",
  },
  failureText: {
    color: "red",
  },
  multipleChipContaienr: {
    display: "flex",
    flexWrap: "wrap",
    width: "84%",
    marginTop: "0",
  },
  chipData: {
    margin: "0 10px 10px 0",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  groupedDropdownContainer: {
    border: "1px solid #C4C4C4",
    width: "80%",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    padding: "12px 10px",
  },
  groupedDropdown: {
    width: "35%",
  },
  groupedDropdownText: {
    marginLeft: "30px",
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: "2px",
  },
  blueStatusCode: {
    color: "#0000FF",
  },
  greenStatusCode: {
    color: "#388E3C",
  },
  orangeStatusCode: {
    color: "#FF7800",
  },
  redStatusCode: {
    color: "#FF0000",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    width: "82%",
    textAlign: "left",
    marginBottom: "8px",
  },
  emailMultiInputField: {
    display: "flex",
    width: "84%",
    alignItems: "center !important",
    height: "80px",
    marginTop: "12px",
  },
  addBtn: {
    width: "80px",
    marginLeft: "15px !important",
    height: "52px !important",
    fontWeight: "bold",
  },
  emailInputField: {
    height: "auto !important",
  },
  selectInputField: {
    width: "80%",
    margin: "20px",
  },
  formLabel: {
    marginBottom: "10px",
  },
  submitBtn: {
    marginTop: "20px",
  },
  infoMsg: {
    color: "blue",
    width: "82%",
    textAlign: "left",
    marginBottom: "10px",
  },
}));

export default useStyles;
