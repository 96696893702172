export const JWT_TOKEN = "jwt_token";

export const EMPTY_STRING = "";

export const NOTIFICATION_MESSAGES = {
  COLOR_FAILURE: "failure",
  COLOR_SUCCESS: "success",
  PLEASE_FILL_MESSAGE: "Please Fill All Fields",
  UNKNOWN_ERROR: "Unknown Error Occured!",
};

export const FORM_NAMES = {
  EMAIL: "email",
  WEBHOOK_URL: "webhook_url",
  NOTIFICATION_TYPE: "notification_type",
  SLACK: "slack",
  URL: "url",
  TIME_INTERVAL_IN_MINUTES: "timeInterval",
  SUCCESS_HTTP_CODE: "successHttpCode",
  REQUESTED_SOURCE: "RequestSource",
  APPLICATION_ENVIRONMENT: "applicationEnviroment",
  APP_NAME: "appName",
};

export const FORM_LABEL_PLACEHOLDERS = {
  URL: "URL",
  TIME_INTERVAL_IN_MINUTES: "Time Interval (In Minutes)",
  SUCCESS_HTTP_CODE: "Success HTTP Status Code",
  DEVELOPMENT: "Development",
  SLACK: "SLACK",
  EMAIL: "EMAIL",
  WEBHOOK_URL: "WEBHOOK_URL",
  PROD: "Prod",
  QA: "QA",
  FRONTEND: "Frontend",
  BACKEND: "Backend",
  STATUS_CODES: "Status codes",
};

export const VARIANT_STYLE = {
  OUTLINED: "outlined",
  CONTAINED: "contained",
  INLINE: "inline",
};

export const VARIANT_TYPE = {
  NUMBER: "number",
  SUBMIT: "submit",
};

export const DEFAULT_VALUES = {
  FRONTEND: "frontend",
  BACKEND: "backend",
  PROD: "prod",
  QA: "qa",
  DEVELOPMENT: "development",
};

export const INPUT_PROPS = {
  ZERO: 0,
  NUMERIC: "numeric",
  PATTERN: "[0-9]*",
};

export const STATUS_CODES = [
  {
    statusCode: 100,
    statusCodeText: "Continue",
  },
  {
    statusCode: 200,
    statusCodeText: "OK",
  },
  {
    statusCode: 201,
    statusCodeText: "Created",
  },
  {
    statusCode: 203,
    statusCodeText: "Non - Authoritative Information",
  },
  {
    statusCode: 204,
    statusCodeText: "No Content",
  },
  {
    statusCode: 226,
    statusCodeText: "IM Used",
  },
  {
    statusCode: 301,
    statusCodeText: "Permanent Redirect",
  },
  {
    statusCode: 302,
    statusCodeText: "Found",
  },
  {
    statusCode: 303,
    statusCodeText: "See Other",
  },
  {
    statusCode: 307,
    statusCodeText: "Temporary Redirect",
  },
  {
    statusCode: 400,
    statusCodeText: "Bad Request",
  },
  {
    statusCode: 401,
    statusCodeText: "Unauthorized",
  },
  {
    statusCode: 403,
    statusCodeText: "Forbidden",
  },
  {
    statusCode: 404,
    statusCodeText: "Not Found",
  },
  {
    statusCode: 409,
    statusCodeText: "Conflict",
  },
  {
    statusCode: 500,
    statusCodeText: "Internal Server Error",
  },
  {
    statusCode: 501,
    statusCodeText: "Not Implemented",
  },
  {
    statusCode: 502,
    statusCodeText: "Bad Gateway",
  },
  {
    statusCode: 503,
    statusCodeText: "Service Unavailable",
  },
  {
    statusCode: 504,
    statusCodeText: "Gateway Timeout",
  },
];

export const INFORMATIONAL = "INFORMATIONAL";
export const SUCCESSFUL = "SUCCESSFUL";
export const REDIRECTION = "REDIRECTION";
export const CLIENT_ERROR = "CLIENT_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";

export const INFORMATIONAL_LABEL = "100 – 199";
export const SUCCESSFUL_LABEL = "200 – 299";
export const REDIRECTION_LABEL = "300 – 399";
export const CLIENT_ERROR_LABEL = "400 – 499";
export const SERVER_ERROR_LABEL = "500 – 599";

export const INTEGER_ZERO = 0;
export const PATTERNS = {
  EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
};

export const KEYS = {
  ENTER: "Enter",
};

export const ERRORS = {
  EMAIL: {
    DUPLICATE: "* Duplicate emails are not allowed",
    NOT_VALID: "* Please enter valid email",
  },
};

export const CRON_SUCCESSFULLY_DELETED = "Cron successfully Deleted";
export const TITLES = {
  HEALTH_STATUS: "Health Status",
  TIME: "Time",
  RESPONSE_TIME: "Response Time"
}

export const HOME_ROUTE = "/home";
export const REGISTER_APP_ROUTE = "/register-app";
export const APPID_ROUTE = "/apps/:id";
export const APPYHIGH_ACCOUNT = "@appyhigh.com";
export const CLIPBOARD = {
  COPIED: "Copied",
  FAILED_TO_COPY: "Failed to copy!"
}

export const JANUARY = "January";

export const EVENT_TYPE = {
  KEY_DOWN: "keydown",
  KEY_SHIFT: "Shift",
  KEY_TAB: "Tab"
}

export const CLICKAWAY = "clickaway";

export const TABLABELS = {
  BASIC_INFO: "Basic Info",
  NOTIFICATIONS: "Notifications",
  SSL: "SSL",
  SETTINGS: "Settings"
}

export const ENDPOINT_COPIED = "Endpoint Copied";
export const CHECK_INTERVAL = "Check Interval";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: TITLES.HEALTH_STATUS,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
        maxTicksLimit: 1,
      },
      title: {
        display: true,
        text: TITLES.TIME
      },
    },
    y: {
      title: {
        display: true,
        text: TITLES.RESPONSE_TIME,
      },
    },
  },
};