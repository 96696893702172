import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Container,
  IconButton,
  Snackbar,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Stack,
  Grid,
  Drawer,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import ContentCopy from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { getCronAppDataById, deleteEndpoint } from "../../store/action/registerAction";
import Cookies from "js-cookie";
import { CHECK_INTERVAL, CLICKAWAY, CLIPBOARD, CRON_SUCCESSFULLY_DELETED, EMPTY_STRING, ENDPOINT_COPIED, EVENT_TYPE, HOME_ROUTE, JWT_TOKEN, options, TABLABELS, TITLES } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "./form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useStyles from "./styles";

const Detail = () => {
  const classes = useStyles();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [healthCheckEndpointData, setHealthCheckEndpointData] = useState();

  const [data, setData] = useState();
  const [notification, setNotification] = useState(EMPTY_STRING);
  const params = useParams();
  const [checkInterval, setCheckInterval] = React.useState(EMPTY_STRING);
  const notify = () => toast(CRON_SUCCESSFULLY_DELETED);
  const [open, setOpen] = React.useState(false);
  const [openFormFrom, setOpenFormFrom] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, drawerStatus) => (event) => {
    if (
      event.type === EVENT_TYPE.KEY_DOWN &&
      (event.key === EVENT_TYPE.KEY_TAB || event.key === EVENT_TYPE.KEY_SHIFT)
    ) {
      return;
    }

    setOpenFormFrom({ ...openFormFrom, [anchor]: drawerStatus });
  };

  const handleCheckIntervalChange = (event) => {
    setCheckInterval(event.target.value);
  };

  const handleDeleteEndpoint = () => {
    deleteEndpoint(params.id);
    navigate(HOME_ROUTE);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      handleClick();
      setNotification(CLIPBOARD.COPIED);
    } catch (err) {
      setNotification(CLIPBOARD.FAILED_TO_COPY);
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === CLICKAWAY) {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    getCronAppDataById(params.id)
      .then((endPoints) => {
        const {apps} = endPoints.data.data;
        setHealthCheckEndpointData(apps);

        let colors = [],
          resTimes = [],
          labels = [];
        for (
          let i = Math.max(0, apps[0].previousResponseTimes?.length - 101);
          i < apps[0].previousResponseTimes?.length;
          i++
        ) {
          resTimes.push(apps[0].previousResponseTimes[i]?.resTime);
          labels.push(
            moment(apps[0].previousResponseTimes[i]?.date).format("lll")
          );
          if (apps[0].previousResponseTimes[i]?.status === "Up") {
            colors.push("rgba(53, 162, 235, 0.5)");
          } else {
            colors.push("rgba(215, 29, 34, 1)");
          }
        }

        let graphData = {
          labels: labels,
          datasets: [
            {
              label: TITLES.HEALTH_STATUS,
              data: resTimes,
              backgroundColor: colors,
            },
          ],
        };

        setData(graphData);
      });
  }, []);

  return (
    <>
      <nav
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          padding: "1rem 3.5rem",
          boxSizing: "border-box",
          backgroundColor: "#5F9F9F",
        }}
      >
        <Button
          style={{
            color: "#fff",
            fontWeight: "600",
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to Dashboard
        </Button>

        <Button
          style={{
            color: "#fff",
            fontWeight: "600",
          }}
          onClick={() => {
            Cookies.remove(JWT_TOKEN);
            window.location.reload();
          }}
        >
          Logout
        </Button>
      </nav>
      {healthCheckEndpointData?.map(
        ({
          _id,
          appName,
          applicationEnviroment,
          healthCheckTimeIntervalInMinute,
          previousHealthCheckStatus,
          requestSource,
          successHttpCode,
          url,
          sslDetails,
          validTill,
          alertsEnabled,
        }) => (
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                      >
                        <Tab label={TABLABELS.BASIC_INFO} value="1" />
                        <Tab label={TABLABELS.NOTIFICATIONS} value="2" />
                        <Tab label={TABLABELS.SSL} value="3" />
                        <Tab label={TABLABELS.SETTINGS} value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <span className={classes.textHead}>Endpoint: </span>
                      <span> {url} </span>
                      <IconButton aria-label="Example">
                        <ContentCopy onClick={() => copyToClipBoard(url)} />
                      </IconButton>
                      <br></br>
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={ENDPOINT_COPIED}
                        action={action}
                      />
                      <span className={classes.textHead}>App Name: </span>
                      <span> {appName.toUpperCase()} </span>
                      <br></br>
                      <span className={classes.textHead}>
                        Application Enviroment:{" "}
                      </span>
                      <span> {applicationEnviroment.toUpperCase()} </span>
                      <br></br>
                      <span className={classes.textHead}>SSL: </span>
                      <span> {sslDetails?.status?.toUpperCase() || ""} </span>
                      <br></br>
                      <span className={classes.textHead}>
                        Expected Status Code:{" "}
                      </span>
                      <span> {successHttpCode} </span>
                      <br></br>
                    </TabPanel>
                    <TabPanel value="2">
                      <span className={classes.textHead}>
                        Alerts Enabled:{" "}
                      </span>
                      <span> {alertsEnabled ? "Enabled" : "Disabled"} </span>
                      <br></br>
                      <span className={classes.textHead}>
                        Check Interval:{" "}
                      </span>
                      <span>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                          <InputLabel id="demo-select-small">
                            Check Interval
                          </InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={checkInterval}
                            label={CHECK_INTERVAL}
                            onChange={handleCheckIntervalChange}
                          >
                            <MenuItem value={1}>1 Minute</MenuItem>
                            <MenuItem value={2}>2 Minutes</MenuItem>
                            <MenuItem value={3}>3 Minutes</MenuItem>
                            <MenuItem value={5}>5 Minutes</MenuItem>
                            <MenuItem value={10}>10 Minutes</MenuItem>
                            <MenuItem value={30}>30 Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </span>
                      <br></br>
                    </TabPanel>
                    <TabPanel value="3">
                      <span className={classes.textHead}>Status: </span>
                      <span> {sslDetails?.status?.toUpperCase()} </span>
                      <br></br>
                      <span className={classes.textHead}>Subject: </span>
                      <span> {sslDetails?.subject} </span>
                      <br></br>
                      <span className={classes.textHead}>Valid From: </span>
                      <span>
                        {" "}
                        {moment(sslDetails?.validFrom).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                      <br></br>
                      <span className={classes.textHead}>Valid To: </span>
                      <span>
                        {" "}
                        {moment(sslDetails?.validTill).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </span>
                      <br></br>
                      <span className={classes.textHead}>Expire: </span>
                      <span> in {sslDetails?.expiry} days </span>
                      <br></br>
                    </TabPanel>
                    <TabPanel value="4">
                      <Stack direction="column" spacing={2}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleDeleteEndpoint();
                            notify();
                          }}
                          startIcon={<DeleteIcon />}
                        >
                          Delete Monitor
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={toggleDrawer("right", true)}
                          startIcon={<BorderColorIcon />}
                        >
                          Edit Details
                        </Button>
                        <Drawer
                          anchor={"right"}
                          open={openFormFrom["right"]}
                          onClose={toggleDrawer("right", false)}
                        >
                          <Form data={healthCheckEndpointData} />
                        </Drawer>
                      </Stack>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
              <Grid
                className={classes.serviceStatusGrid}
                xs={4}
              >
                <span style={{ fontWeight: "bold", fontSize: "25px" }}>
                  Service Status
                </span>
                <div
                  className={classes.serviceStatus}
                  style = {{
                    background:
      previousHealthCheckStatus === "Up" ? "#18c418" : "#ea1b1b",
                  }}
                >
                  <span> {previousHealthCheckStatus} </span>
                </div>
              </Grid>
            </Grid>
          </Container>
        )
      )}

      <Container
        maxWidth="lg"
        style={{
          marginBottom: "5em",
        }}
      >
        {data && <Bar options={options} data={data} />}
      </Container>
    </>
  );
};

export default Detail;
