import {
  Paper,
  Select,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  getAppData,
  getCronAppData,
  getCronAppDataByAppId,
} from "../../store/action/registerAction";
import useStyles from "./styles";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { JWT_TOKEN } from "../../utils/constants";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [appId, setAppId] = useState("All Apps");
  const [apps, setApps] = useState([{ key: "Select", value: "" }]);
  const [healthCheckTableData, setHealthCheckTableData] = useState([]);

  
  const handleAppIdChange = e => {
    setAppId(e.target.value);
    getCronAppDataByAppId(e.target.value)
    .then((endPoints) => endPoints.data)
    .then((endPointData) => endPointData.data)
      .then(cronApps => {
        setHealthCheckTableData(cronApps);
      });
  };
  useEffect(() => {
    getAppData()
    .then((appModelForORG) => appModelForORG.data)
    .then((appsData) => appsData.data)
      .then(apps => apps[0].apps)
      .then(apps => {
        const appsData = apps.map(app => ({
          value: app._id,
          key: app.appName,
        }));
        setApps(appsData);
      });
    getCronAppData()
    .then((appModelForORG) => appModelForORG.data)
    .then((appsData) => appsData.data)
      .then((cronApps) => {
        setHealthCheckTableData(cronApps);
      });
  }, []);

  return (
    <>
      <nav
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          padding: "1rem 3.5rem",
          boxSizing: "border-box",
          backgroundColor: "#5F9F9F",
        }}
      >
        <Button
          style={{
            color: "#fff",
            fontWeight: "600",
          }}
          onClick={() => {
            navigate("/register-app");
          }}
        >
          Register Health-Check Endpoint
        </Button>

        <Button
          style={{
            color: "#fff",
            fontWeight: "600",
          }}
          onClick={() => {
            Cookies.remove(JWT_TOKEN);
            window.location.reload();
          }}
        >
          Logout
        </Button>
        <ToastContainer />

      </nav>
      <Paper className={classes.container}>
        <FormControl fullWidth className={classes.selectApp}>
          <InputLabel id="demo-simple-select-label" name="appName">
            Select Your App
          </InputLabel>
          <Select
            name="appName"
            value={appId}
            label="Select App"
            onChange={(e) => handleAppIdChange(e)}
          >
            {apps.map((app) => (
              <MenuItem value={app.value}>{app.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography
          variant="h5"
          style={{ textAlign: "center", margin: "20px 0 10px 0" }}
        >
          Health Check Data
        </Typography>
        <hr />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>App Name</TableCell>
              <TableCell className={classes.header}>
                Application Enviroment
              </TableCell>
              <TableCell className={classes.header}>Url</TableCell>
              <TableCell className={classes.header}>Previous Status</TableCell>
              <TableCell className={classes.header}>
                Health Check Interval (In Mins)
              </TableCell>
              <TableCell className={classes.header}>Request Source</TableCell>
              <TableCell className={classes.header}>Http Code</TableCell>
              <TableCell className={classes.header}>Endpoint Valid Till</TableCell>
            </TableRow>
          </TableHead>
          {healthCheckTableData.map(
            ({
              _id,
              appName,
              applicationEnviroment,
              healthCheckTimeIntervalInMinute,
              previousHealthCheckStatus,
              requestSource,
              successHttpCode,
              url,
              validTill,
            }) => (
              <TableBody onClick={() => {
                navigate(`/apps/${_id}`);
              }}>
                <TableCell className={classes.tableCell}>{appName}</TableCell>
                <TableCell className={classes.tableCell}>
                  {applicationEnviroment}
                </TableCell>
                <TableCell className={classes.tableCell}>{url}</TableCell>
                <TableCell
                  className={classes.tableCell}
                >{`${previousHealthCheckStatus}`}</TableCell>
                <TableCell className={classes.tableCell}>
                  {healthCheckTimeIntervalInMinute}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {requestSource}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {successHttpCode}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {new Date(validTill).toLocaleDateString() +
                    " " +
                    new Date(validTill).toLocaleTimeString()}
                </TableCell>
              </TableBody>
            )
          )}
        </Table>
      </Paper>
    </>
  );
};

export default Dashboard;
