import React from "react";
import { useEffect, useState } from "react";
import { getAppData, updateAction } from "../../store/action/registerAction";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Button,
  FormGroup,
  Box,
  Chip,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  DEFAULT_VALUES,
  EMPTY_STRING,
  ERRORS,
  FORM_LABEL_PLACEHOLDERS,
  FORM_NAMES,
  INPUT_PROPS,
  KEYS,
  NOTIFICATION_MESSAGES,
  STATUS_CODES,
  VARIANT_STYLE,
  VARIANT_TYPE,
  INFORMATIONAL,
  SUCCESSFUL,
  REDIRECTION,
  CLIENT_ERROR,
  SERVER_ERROR,
  INTEGER_ZERO,
} from "../../utils/constants";
import {
  getStatusCodeTypeAndLabel,
  numberInputOnWheelPreventChange,
  validateEmail,
} from "../../commonHelper";
import useStyles from "./styles";

const Form = (props) => {
  const classes = useStyles();
  const [url, setUrl] = useState(EMPTY_STRING);
  const [timeInterval, setTimeInterval] = useState(EMPTY_STRING);
  const [successHttpCode, setSuccessHttpCode] = useState(INTEGER_ZERO);
  const [requestSource, setRequestSource] = useState(EMPTY_STRING);
  const [applicationEnviroment, setApplicationEnviroment] =
    useState(EMPTY_STRING);
  const [appId, setAppId] = useState(EMPTY_STRING);
  const [apps, setApps] = useState([{ key: "Select", value: EMPTY_STRING }]);
  const [formMessage, setFormMessage] = useState(EMPTY_STRING);
  const [formMessageColor, setFormMessageColor] = useState("#");
  const [notificationType, setNotificationType] = useState([]);
  const [webHookUrl, setWebHookUrl] = useState(EMPTY_STRING);
  const [statusCodeText, setStatusCodeText] = useState(EMPTY_STRING);
  const [statusCodeType, setStatusCodeType] = useState(EMPTY_STRING);
  const [showStatusText, setShowStatusText] = useState(false);
  const [emailInput, setEmailInput] = useState(EMPTY_STRING);
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState(EMPTY_STRING);
  const [healthCheckEndpointData, setHealthCheckEndpointData] = useState();
  const params = useParams();

  const handleInputChange = (e, setChange) => {
    setFormMessage(EMPTY_STRING);
    setChange(e.target.value);
  };

  useEffect(() => {
    handleEmailAdd(emailInput, false);
  }, [emailInput]);
  const resetForm = () => {
    window.location.reload();
    // TODO:
    // Temporary solution, actually we need to clear all fields
  };

  const clearFormMessage = () => {
    setTimeout(() => {
      setFormMessage(EMPTY_STRING);
    }, 3000);
  };

  useEffect(() => {
    getAppData()
      .then((appModelForORG) => appModelForORG.data)
      .then((appsData) => appsData.data)
      .then(apps => apps[0].apps)
      .then((apps) => {
        const appsData = apps.map((app) => ({
          value: app._id,
          key: app.appName,
        }));
        setApps(appsData);
      });
      setHealthCheckEndpointData(props.data);
  }, []);

  const handleSetFormMessage = (message) => {
    setFormMessageColor(NOTIFICATION_MESSAGES.COLOR_FAILURE);
    setFormMessage(message);
  };

  const validation = (data) => {
    let result = true;
    Object.keys(data).map((property) => {
      switch (property) {
        case FORM_NAMES.NOTIFICATION_TYPE:
          data.notification_type.map((each) => {
            if (each === FORM_NAMES.SLACK) {
              if (!data.webhook_url || data.webhook_url.length === 0) {
                handleSetFormMessage(NOTIFICATION_MESSAGES.PLEASE_FILL_MESSAGE);
                result = false;
                return;
              }
            } else if (each === FORM_NAMES.EMAIL) {
              if (!data.email || data.email.length === 0) {
                handleSetFormMessage(NOTIFICATION_MESSAGES.PLEASE_FILL_MESSAGE);
                result = false;
                return;
              }
            }
          });
          break;
        default:
          if (!data[property]) {
            handleSetFormMessage(NOTIFICATION_MESSAGES.PLEASE_FILL_MESSAGE);
            result = false;
            return;
          }
          break;
      }
    });
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      appId: appId || healthCheckEndpointData[0].appId,
      url: url || healthCheckEndpointData[0].url,
      healthCheckTimeIntervalInMinute: timeInterval || healthCheckEndpointData[0].healthCheckTimeIntervalInMinute,
      requestSource: requestSource || healthCheckEndpointData[0].requestSource,
      successHttpCode: successHttpCode || healthCheckEndpointData[0].successHttpCode,
      applicationEnviroment: applicationEnviroment || healthCheckEndpointData[0].applicationEnviroment,
      notification_type: notificationType,
    };
    
    if (notificationType.includes(FORM_NAMES.SLACK)) {
      data[FORM_NAMES.WEBHOOK_URL] = webHookUrl;
    }
    if (notificationType.includes(FORM_NAMES.EMAIL)) {
      data[FORM_NAMES.EMAIL] = emails;
    }
    if (validation(data)) {
      handleSetFormMessage(EMPTY_STRING);
      updateAction(data, params.id).then((res) => {
        if (res.data) {
          setFormMessage(res.data.message);
          setFormMessageColor(NOTIFICATION_MESSAGES.COLOR_SUCCESS);
          clearFormMessage();
          resetForm();
        } else {
          setFormMessageColor(NOTIFICATION_MESSAGES.COLOR_FAILURE);
          if (res.response && res.response.data && res.response.data.error) {
            setFormMessage(res.response.data.error.message);
          } else {
            setFormMessage(NOTIFICATION_MESSAGES.UNKNOWN_ERROR);
          }
        }
      });
      setEmailInput(EMPTY_STRING);
    }
  };

  const handleNotificationType = (event) => {
    setFormMessage(EMPTY_STRING);
    if (event.target.checked) {
      setNotificationType([...notificationType, event.target.name]);
    } else {
      const updateNotificationType = notificationType.filter(
        (eachName) => eachName !== event.target.name
      );
      if (event.target.name === FORM_NAMES.SLACK) {
        setWebHookUrl();
      } else {
        setEmailInput();
      }
      setNotificationType([...updateNotificationType]);
    }
    if (event.target.name === FORM_NAMES.EMAIL) {
      setEmails([]);
    } else if (event.target.name === FORM_NAMES.SLACK) {
      setWebHookUrl(EMPTY_STRING);
    }
    setEmailInput(EMPTY_STRING);
    setEmailError(false);
    setEmailErrorMsg(EMPTY_STRING);
  };

  const emailKeyHandler = (event) => {
    if (event.key === KEYS.ENTER) {
      handleEmailAdd(event.target.value, true);
    }
  };

  const handleEmailAdd = (newEmail, onClick) => {
    const hasValidEmail = validateEmail(newEmail);
    if (hasValidEmail) {
      setEmailError(false);
      const previousEmails = emails;
      if (!previousEmails.includes(newEmail) && onClick) {
        setEmails([...emails, newEmail]);
        setEmailError(false);
        setEmailInput(EMPTY_STRING);
      } else if (onClick) {
        setEmailError(true);
        setEmailErrorMsg(ERRORS.EMAIL.DUPLICATE);
      }
    } else {
      setEmailError(true);
      setEmailErrorMsg(ERRORS.EMAIL.NOT_VALID);
    }
  };

  const handleEmailDelete = (index) => {
    let updatedEmails = emails;
    updatedEmails.splice(index, 1);
    setEmails([...updatedEmails]);
    if (emails.length === 0) {
      setEmailErrorMsg(EMPTY_STRING);
    }
  };

  const options = STATUS_CODES.map((statusCodeObj) => {
    const { statusCodeLabel: category } = getStatusCodeTypeAndLabel(
      statusCodeObj.statusCode
    );
    return {
      category,
      ...statusCodeObj,
    };
  });

  const statusCodeHandler = (event, value) => {
    if (value === null) {
      setShowStatusText(false);
    } else {
      setShowStatusText(true);
      setStatusCodeText(value.statusCodeText);
      setSuccessHttpCode(value.statusCode);
      const { statusCodeType } = getStatusCodeTypeAndLabel(value.statusCode);
      setStatusCodeType(statusCodeType);
    }
  };

  return (
   <Box className="box">
       {healthCheckEndpointData?.map(({
          _id,
          appName,
          applicationEnviroment,
          healthCheckTimeIntervalInMinute,
          requestSource,
          successHttpCode,
          url,
          validTill,
          alertsEnabled,
        }) => ( <Box className={clsx("box-secondary", classes.form)}>
        <Box className={classes.formContainer}>
          <Typography className={classes.formHeading}>
            <BorderColorIcon />  &nbsp;  Edit Health-Check Endpoint
          </Typography>

          {/* 1) TextField */}
          <TextField
            style={{ width: "420px", height: "32px" }}
            placeholder={FORM_LABEL_PLACEHOLDERS.URL}
            label={FORM_LABEL_PLACEHOLDERS.URL}
            name={FORM_NAMES.URL}
            variant={VARIANT_STYLE.OUTLINED}
            className={classes.inputField}
            defaultValue={url}
            onChange={(e) => handleInputChange(e, setUrl)}
          />
          <br />

          {/* 2) TextField */}
          <TextField
            type={VARIANT_TYPE.NUMBER}
            style={{ width: "420px", height: "32px" }}
            inputProps={{
              min: INPUT_PROPS.ZERO,
              inputMode: INPUT_PROPS.NUMERIC,
              pattern: INPUT_PROPS.PATTERN,
            }}
            placeholder={FORM_LABEL_PLACEHOLDERS.TIME_INTERVAL_IN_MINUTES}
            label={FORM_LABEL_PLACEHOLDERS.TIME_INTERVAL_IN_MINUTES}
            name={FORM_NAMES.TIME_INTERVAL_IN_MINUTES}
            variant={VARIANT_STYLE.OUTLINED}
            defaultValue={healthCheckTimeIntervalInMinute}
            onChange={(e) => handleInputChange(e, setTimeInterval)}
            className={classes.inputField}
            onWheel={numberInputOnWheelPreventChange}
          />
          <br />
          <Box className={classes.groupedDropdownContainer}>
            <Autocomplete
              options={options.sort((a, b) => a.statusCode - b.statusCode)}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.statusCode.toString()}
              className={classes.groupedDropdown}
              onChange={statusCodeHandler}
              clearOnEscape
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={FORM_LABEL_PLACEHOLDERS.STATUS_CODES}
                />
              )}
            />
            {showStatusText && (
              <Box className={classes.groupedDropdownTextContainer}>
                <Typography
                  className={clsx(classes.groupedDropdownText, {
                    [classes.blueStatusCode]: statusCodeType === INFORMATIONAL,
                    [classes.greenStatusCode]: statusCodeType === SUCCESSFUL,
                    [classes.orangeStatusCode]: statusCodeType === REDIRECTION,
                    [classes.redStatusCode]:
                      statusCodeType === CLIENT_ERROR ||
                      statusCodeType === SERVER_ERROR,
                  })}
                >
                  {statusCodeText}
                </Typography>
              </Box>
            )}
          </Box>
          <br />

          {/* Radio Buttons */}
          <FormControl
            name={FORM_NAMES.REQUESTED_SOURCE}
            className={classes.inputField}
          >
            <FormLabel>Request Source</FormLabel>
            <RadioGroup
              row
              name={FORM_NAMES.REQUESTED_SOURCE}
            defaultValue={requestSource}
              onChange={(e) => handleInputChange(e, setRequestSource)}
            >
              <FormControlLabel
                value={DEFAULT_VALUES.BACKEND}
                control={<Radio />}
                label={FORM_LABEL_PLACEHOLDERS.BACKEND}
              />
              <FormControlLabel
                value={DEFAULT_VALUES.FRONTEND}
                control={<Radio />}
                label={FORM_LABEL_PLACEHOLDERS.FRONTEND}
              />
            </RadioGroup>
          </FormControl>

          <FormControl
            name={FORM_NAMES.APPLICATION_ENVIRONMENT}
            className={classes.inputField}
          >
            <FormLabel>Application Enviroment</FormLabel>
            <RadioGroup
              row
              name={FORM_NAMES.APPLICATION_ENVIRONMENT}
              defaultValue={applicationEnviroment}
              onChange={(e) => handleInputChange(e, setApplicationEnviroment)}
            >
              <FormControlLabel
                value={DEFAULT_VALUES.DEVELOPMENT}
                control={<Radio />}
                label={FORM_LABEL_PLACEHOLDERS.DEVELOPMENT}
              />
              <FormControlLabel
                value={DEFAULT_VALUES.PROD}
                control={<Radio />}
                label={FORM_LABEL_PLACEHOLDERS.PROD}
              />
              <FormControlLabel
                value={DEFAULT_VALUES.QA}
                control={<Radio />}
                label={FORM_LABEL_PLACEHOLDERS.QA}
              />
            </RadioGroup>
          </FormControl>
          <FormControl style={{ width: "80%" }}>
            <FormLabel>Notification Type</FormLabel>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationType.includes(FORM_NAMES.SLACK)}
                    onChange={handleNotificationType}
                    name={FORM_NAMES.SLACK}
                  />
                }
                label={FORM_LABEL_PLACEHOLDERS.SLACK}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationType.includes(FORM_NAMES.EMAIL)}
                    onChange={handleNotificationType}
                    name={FORM_NAMES.EMAIL}
                  />
                }
                label={FORM_LABEL_PLACEHOLDERS.EMAIL}
              />
            </FormGroup>
          </FormControl>

          {notificationType.includes(FORM_NAMES.SLACK) && (
            <>
              <TextField
                style={{ width: "420px", height: "32px" }}
                placeholder={FORM_LABEL_PLACEHOLDERS.WEBHOOK_URL}
                label={FORM_LABEL_PLACEHOLDERS.WEBHOOK_URL}
                name={FORM_NAMES.WEBHOOK_URL}
                variant={VARIANT_STYLE.OUTLINED}
                className={classes.inputField}
                value={webHookUrl}
                onChange={(e) => handleInputChange(e, setWebHookUrl)}
              />
              <br />
            </>
          )}

          {notificationType.includes(FORM_NAMES.EMAIL) && (
            <>
              <Box className={classes.emailMultiInputField}>
                <TextField
                  style={{ width: "420px", height: "32px" }}
                  placeholder={FORM_LABEL_PLACEHOLDERS.EMAIL}
                  label={FORM_LABEL_PLACEHOLDERS.EMAIL}
                  name={FORM_NAMES.EMAIL}
                  variant={VARIANT_STYLE.OUTLINED}
                  className={classes.emailInputField}
                  value={emailInput}
                  onChange={(e) => handleInputChange(e, setEmailInput)}
                  onKeyDown={emailKeyHandler}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleEmailAdd(emailInput, true)}
                  className={classes.addBtn}
                >
                  ADD
                </Button>
              </Box>
              <Typography className={classes.infoMsg}>
                Emails Added: {emails.length}
              </Typography>
              {emailError && emailInput.length > 0 && (
                <Typography className={classes.errorMsg}>
                  {emailErrorMsg}
                </Typography>
              )}
              {Array.isArray(emails) && emails.length > 0 && (
                <Box className={classes.multipleChipContaienr}>
                  {emails.map((eachEmail, index) => (
                    <Chip
                      className={classes.chipData}
                      onDelete={(e) => handleEmailDelete(index)}
                      label={eachEmail}
                      key={index}
                    />
                  ))}
                </Box>
              )}
              <br />
            </>
          )}

          {/* Select */}
          <FormControl fullWidth className={classes.selectInputField} defaultValue="hedcfg">
            <InputLabel
              id="demo-simple-select-label"
              name={FORM_NAMES.APP_NAME}
            >
              Select Your App
            </InputLabel>

            <Select
              name={FORM_NAMES.APP_NAME}
              defaultValue={appName}
              renderValue={(value) => value}
              onChange={(e) => handleInputChange(e, setAppId)}
            >
              {apps.map((app) => (
                <MenuItem value={app.value} key={app.key}>
                  {app.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*  Switch */}

          {/* Checkbox */}
          {formMessage.length > 0 && (
            <Typography
              variant="h6"
              className={clsx(
                classes.formMessage,
                formMessageColor === NOTIFICATION_MESSAGES.COLOR_SUCCESS
                  ? classes.successText
                  : classes.failureText
              )}
            >
              {formMessage}
            </Typography>
          )}
          <Button
            className={classes.submitBtn}
            variant={VARIANT_STYLE.CONTAINED}
            color="primary"
            type={VARIANT_TYPE.SUBMIT}
            onClick={handleSubmit}
          >
            submit
          </Button>
        </Box>
      </Box>
    ))}

    </Box>
  );
};

export default Form;